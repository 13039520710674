import React, { startTransition, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isPending, setIsPending] = useState(false);

  const changeLanguage = (lng: string) => {
    setIsPending(true);  // Start showing a loading indicator

    startTransition(() => {
      i18n.changeLanguage(lng).then(() => {
        setIsPending(false);  // Stop showing the loading indicator once done
      });
    });
  };

  return (
    <div style={{ height: '30px', width: '100px', gap: '4px', display: 'flex' }} className='lang'>
      <button style={{ border: "none", backgroundColor: "transparent" }} disabled={isPending}>
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => changeLanguage('en')}
          src="/assets/images/england.png"
          alt="English"
          width="24"
          height="24"
        />
      </button>
      <button style={{ border: "none", backgroundColor: "transparent" }} disabled={isPending}>
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => changeLanguage('ru')}
          src="/assets/images/russia.png"
          alt="Русский"
          width="24"
          height="24"
        />
      </button>
    </div>
  );
};

export default LanguageSwitcher;

