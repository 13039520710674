import React from 'react';
import './styles/main.scss';
import './styles/style-mobile.scss';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import Home from './pages/Home/index.tsx';
import Industrial from './pages/Industrial/index.tsx';
import { Route, Routes } from 'react-router-dom';
import Navbar from "./components/layout/navbar/index.jsx"
import Footer from './components/footer.tsx';
import Chemicals from './pages/Chemicals/index.tsx';
import Contact from './pages/Contact/index.tsx';
import Mining from './pages/Mining/index.tsx'
import OurProject from './pages/Our-project/index.tsx';
import Pipelines from './pages/Pipelines/index.tsx';
import Refining from './pages/Refining/index.tsx';
import TankFarms from './pages/Tankfarms/index.tsx';
import Underground from './pages/Underground/index.tsx';
import Upstream from './pages/Upstream/index.tsx';

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/industrial" element={<Industrial />} />
        <Route path="/chemicals" element={<Chemicals />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mining" element={<Mining />} />
        <Route path="/ourproject" element={<OurProject />} />
        <Route path="/pipelines" element={<Pipelines />} />
        <Route path="/refining" element={<Refining />} />
        <Route path="/tankfarms" element={<TankFarms />} />
        <Route path="/underground" element={<Underground />} />
        <Route path="/upstream" element={<Upstream />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
