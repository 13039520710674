import React from "react";

function Location() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#200E32"
        fillRule="evenodd"
        d="M12 2h.033c4.687.018 8.485 3.842 8.467 8.522v.093c-.063 3.345-2.074 6.12-3.75 7.86-.476.497-.984.974-1.507 1.42a.746.746 0 01-.969-1.134c.485-.413.956-.857 1.4-1.32 1.49-1.546 3.279-3.992 3.333-6.853.015-3.93-3.115-7.082-6.98-7.098H12c-3.851 0-6.992 3.122-7.007 6.97a9.12 9.12 0 001.86 5.226c1.343 1.789 3.381 3.683 5.318 4.944a.743.743 0 01-.408 1.37.74.74 0 01-.407-.121C9.282 20.529 7.1 18.5 5.66 16.584a10.607 10.607 0 01-2.16-6.071C3.518 5.786 7.328 2 12 2zm-1.745 8.614c0 .959.782 1.74 1.742 1.74s1.742-.781 1.742-1.74c0-.96-.781-1.74-1.742-1.74a.746.746 0 110-1.49 3.236 3.236 0 013.235 3.23 3.236 3.236 0 01-3.235 3.23 3.236 3.236 0 01-3.235-3.23.746.746 0 011.493 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Location;
