import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n.ts'; // Import the i18n configuration
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Router>,
  document.getElementById('root'),
);
