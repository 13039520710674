import React from 'react'
// import "../styles/main.css"

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-card">
          <span style={{color:"black"}}>© NSI 2023</span>
          <img
            className="footer-card__img"
            src="/assets/images/log.png"
            alt=""
          />
      </div>
    </div>
  )
}

export default Footer
