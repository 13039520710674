import { Button, Drawer, Space } from 'antd';
import { useState } from 'react';
import { MenuFoldOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import RightIcon from './icons/Right-icon';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation('common', 'menu');

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('left');
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navbar = [
    {
      id: 1,
      text: 'menu-project',
      to: '/ourproject/',
    },
    {
      id: 2,
      text: 'menu-industrial',
      to: '/industrial/',
    },
    {
      id: 3,
      text: 'menu-mining',
      to: '/mining/',
    },
    {
      id: 4,
      text: 'menu-contacts',
      to: '/contact/',
    },
  ];
  return (
    <div className="open-modal">
      <Space>
        <Button type="primary" onClick={showDrawer}>
          <MenuFoldOutlined />
        </Button>
      </Space>
      <Drawer
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link onClick={onClose} to={'/'}>
              <img src="/assets/images/log.png" alt="Image" />
            </Link>
            <div style={{ width: '35px' }}>
              <Button onClick={onClose}>
                <CloseOutlined />
              </Button>
            </div>
          </div>
        }
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        closeIcon={<CloseOutlined />}
        handler={false}
        key={placement}
        width={380}
      >
        <div>
          {navbar.map((item) => (
            <Link onClick={onClose} to={item.to}>
              <div className="homeSelect-right__container" key={item.id}>
                <div onClick={onClose} className="homeSelect-right__container-link">
                  <p>{t(item.text, { ns: 'menu' })}</p>
                  <span>
                    <RightIcon />
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Drawer>
    </div>
  );
};
export default App;
