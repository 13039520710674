import React from "react";

function MessageEmail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#200E32"
        fillRule="evenodd"
        d="M22 10.307a.71.71 0 01-.708.713l-.009-.018a.705.705 0 01-.5-.209.716.716 0 01-.207-.504V8.846c-.024-2.426-1.97-4.387-4.379-4.411H7.803c-2.408.024-4.355 1.985-4.379 4.41v6.31c.024 2.426 1.97 4.386 4.379 4.41h8.394c2.409-.024 4.355-1.985 4.379-4.41a.718.718 0 01.712-.642c.365 0 .671.276.712.642-.01 3.224-2.602 5.835-5.803 5.845H7.803C4.6 20.995 2.005 18.38 2 15.155v-6.31C2 5.618 4.598 3 7.803 3h8.394c3.201.01 5.793 2.621 5.803 5.846v1.46zm-9.261 1.55l4.113-3.314h.009a.713.713 0 011 .107.717.717 0 01-.116 1.006l-4.113 3.324a2.841 2.841 0 01-3.582 0L5.926 9.656a.725.725 0 01-.106-1.007.697.697 0 01.991-.106l4.157 3.314c.52.41 1.25.41 1.77 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MessageEmail;
