import React from 'react';
import { useTranslation } from 'react-i18next';
import OpenModal from '../../../components/OpenModal';
import LanguageSwitcher from '../../languageSwithcer.tsx';
import { Link } from 'react-router-dom';

const Index = () => {
  const { t } = useTranslation();
  const navbar = [
    {
      id: 1,
      text: 'menu-project',
      to: '/ourproject',
    },
    {
      id: 2,
      text: 'menu-industrial',
      to: '/industrial',
    },
    {
      id: 3,
      text: 'menu-mining',
      to: '/mining',
    },
    {
      id: 4,
      text: 'menu-contacts',
      to: '/contact',
    },
  ];
  return (
    <div className="header">
      <div className="header-container">
        <div className="header-navbar">
          <div className="header-img">
            <Link href="/">
              <img src="/assets/images/log.png" alt="" />
            </Link>
          </div>
          <div className="header-nav">
            {navbar.map((item) => {
              return<>
              <div style={{ cursor: 'pointer' }} key={item.id}>
                <Link to={item.to}>
                  <p>{t(item.text, { ns: 'menu' })}</p>
                </Link>
              </div>
            </>})}
          </div>
          <div style={{display:"flex"}}>
          <LanguageSwitcher />
          <OpenModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
