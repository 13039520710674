import React from "react";

function CallIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#200E32"
        fillRule="evenodd"
        d="M3.87 5.448c-.515.708-.479 1.614.074 2.829a.75.75 0 01-1.365.62c-.787-1.726-.772-3.167.044-4.28.304-.508 2.215-2.72 3.856-2.613.516.043.988.283 1.431.714.472.461 2.454 2.872 2.614 3.635.17.82-.198 1.46-.467 1.927v.002c-.265.46-.357.657-.266.906.668 1.636 1.703 2.973 3.064 3.945a.75.75 0 01-.873 1.22c-1.595-1.14-2.802-2.696-3.592-4.626-.347-.955.065-1.67.367-2.193.226-.392.35-.626.298-.876-.14-.36-1.603-2.29-2.193-2.865-.182-.18-.353-.284-.48-.294-.716-.017-2.18 1.398-2.512 1.949zm13.778 8.025c.766.16 3.175 2.14 3.636 2.611.434.444.674.917.715 1.403.065 1.842-2.402 3.767-2.685 3.93-.538.387-1.17.583-1.88.583-.705 0-1.49-.194-2.338-.59-3.265-1.355-8.204-5.75-10.982-9.783a.75.75 0 111.235-.85c2.595 3.768 7.335 8.009 10.352 9.262 1.22.567 2.128.602 2.804.117.527-.319 2.024-1.806 1.997-2.58-.01-.09-.113-.262-.29-.442-.576-.59-2.51-2.053-2.913-2.204-.32-.068-.651.126-1.157.444l-.256.16a.75.75 0 01-.774-1.285l.23-.143c.56-.353 1.334-.835 2.306-.633z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CallIcon;
