import React from 'react';
import { Link } from 'react-router-dom';
import SendIcon from './icons/Telegram-icons';
import { useTranslation } from 'react-i18next';

const ContactButton = () => {
  const { t } = useTranslation();

  return (
    <div className="home-button" style={{ display: 'flex', justifyContent: 'end' }}>
      <Link to={'/contact'}>
        <button className="home-button__link">
          <SendIcon /> {t('contact-button')}
        </button>
      </Link>
    </div>
  );
};

export default ContactButton;
