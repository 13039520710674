import React from 'react'
import RightImg from "../../components/icons/Right-img";
import RightIcon from "../../components/icons/Right-icon";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation()
  const banner = {
    src: "/assets/images/home.jpg",
    text: "home-banner",
  };

  const dataSelect = [
    {
      id: 1,
      text: "pipelines",
      to: "/pipelines/",
    },
    {
      id: 2,
      text: "refining",
      to: "/refining/",
    },
    {
      id: 3,
      text: "tankFarms",
      to: "/tankfarms/",
    },
    {
      id: 4,
      text: "underground",
      to: "/underground/",
    },
    {
      id: 5,
      text: "upstream",
      to: "/upstream/",
    },
    {
      id: 6,
      text: "chemicals",
      to: "/chemicals/",
    },
  ];

  const selectText = {
    text: "home-banner",
    label: "home-text",
    description: "home-desc",
    selectText: "home-page",
  };

  return (
    <div>
      <div className="relative1">
        <div
          className="home"
          style={{
            backgroundImage: `url(${banner.src})`,
          }}
        >
          <div className="home-text">
            <p>{t(banner.text, { ns: "common" })}</p>
            <span>
              <RightImg />
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="homeSelect">
          <div className="homeSelect-left">
            {/* <h2>{t(selectText.text, { ns: "common" })}</h2> */}
            <p>{t(selectText.label, { ns: "common" })}</p>
            <h6>{t(selectText.description, { ns: "common" })}</h6>
          </div>
          <div className="homeSelect-right">
            <h3>{t(selectText.selectText, { ns: "common" })}</h3>
            <div>
              {dataSelect.map((item) => (
                <Link to={item.to}>
                  <div className="homeSelect-right__container" key={item.id}>
                    <div
                      style={{ cursor: "pointer" }}
                      className="homeSelect-right__container-link"
                    >
                      <p>{t(item.text, { ns: "common" })}</p>
                      <span>
                        <RightIcon />
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home